import { createContext, useState, useEffect } from "react";
import { getErrorMessageFromError } from "../../components/ErrorMessage";
import { useApp } from "../../hooks/useApp";
import { TemplateDto } from "../../model/TemplatesDto";
import { useNavigate } from "react-router-dom";
import { saveMessageTemplateRequest, updateMessageTemplateRequest, getMessageTemplateRequest, deleteMessageTemplateRequest } from "../../services/templates/MessageTemplatesService";

const TemplatesTwilioDataContext = createContext();

const TemplatesTwilioDataContextProvider = ({ children }) => {
    const [listTemplatesTwilio, setListTemplatesTwilio] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateIdToDelete, setTemplateIdToDelete] = useState(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    
    const [page, setPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const { setSuccessMsg,
        setErrorMsg,
        setLoading,
        messageUser,
        loading,
        authenticate,
        authInfo,
        isAdmin,
    } = useApp();

    const navigate = useNavigate();

    const handleCancel = async () => {
        navigate(-1);
    };

    const onClickActionEditButton = (params) => {
        setSelectedTemplate(params);
    };
        
    const handleChangePage = (event, newPage) => {
        setPage(newPage);        
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    useEffect(() => {
        setTimeout(
            () => loadTemplatesTwilio(null, null), 
            100
        );
    },[page]);

    useEffect(() => {
        if(page == 0)
            setTimeout(
                () => loadTemplatesTwilio(null, null), 
                100
            );
        else
            setPage(0);
    },[rowsPerPage]);

    const handleFormSubmit = async (values) => {
        try {
            values.type='WHATSAPP_TWILIO';
            values.active = values.active ? 1 : 0; 
            const input = {
                ...values
            };
            setLoading(true);
            if (values.id) {
                await updateMessageTemplateRequest(input);
                setSuccessMsg("Editado con éxito");

            } else {
                await saveMessageTemplateRequest(input);
                setSuccessMsg("Creado con éxito")
            }    
            
            setLoading(false);
            if(page == 0)
                setTimeout(
                    () => loadTemplatesTwilio(null, null), 
                    100
                );
            else
                setPage(0);

            handleCancel();
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }

    const loadTemplatesTwilio = async (criterio,active) => {
        if(!authInfo)return;
        try {
            setLoading(true);
            setListTemplatesTwilio([]);
            const params = {
                criterio: criterio,
                type: 'WHATSAPP_TWILIO',
                active: active, 
                page: page,
                size: rowsPerPage
            }
            let responseTemplatesTwilioList = await getMessageTemplateRequest(params);
            setTotalItems(responseTemplatesTwilioList.totalElements);
            const templatesTwilio = responseTemplatesTwilioList.content.map(templateData => {
                return new TemplateDto(
                    templateData.id,
                    templateData.template,
                    templateData.name,
                    templateData.type,
                    templateData.active,
                    templateData.authorized,
                );
            });
            setListTemplatesTwilio(templatesTwilio);
            setLoading(false);
        } catch (error) {
            console.log('error',error)
            setLoading(false);
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }

    const openDeleteConfirmation = (templateId) => {
        setTemplateIdToDelete(templateId);
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setTemplateIdToDelete(null);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteConfirmed = async () => {
        closeDeleteConfirmation();
        if (templateIdToDelete) {
            await handleDelete(templateIdToDelete);
        }
    };

    const handleDelete = async (templateId) => {
        try {
            setLoading(true);
            await deleteMessageTemplateRequest(templateId);
            setSuccessMsg("Eliminado con éxito");
            setLoading(false);
            
            if(page == 0)
                setTimeout(
                    () => loadTemplatesTwilio(null, null), 
                    100
                );
            else
                setPage(0);
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    };

    return (
        <TemplatesTwilioDataContext.Provider value={{
            listTemplatesTwilio,
            loadTemplatesTwilio,
            loading,
            messageUser,
            onClickActionEditButton,
            setSelectedTemplate,
            selectedTemplate,
            showDeleteConfirmation,
            openDeleteConfirmation,
            closeDeleteConfirmation,
            handleDeleteConfirmed,
            handleFormSubmit,
            handleDelete,
            handleCancel,
            page, 
            setPage,
            rowsPerPage, 
            handleChangePage,
            handleChangeRowsPerPage,
            totalItems
        }}>
            {children}
        </TemplatesTwilioDataContext.Provider>
    )
}

export { TemplatesTwilioDataContext, TemplatesTwilioDataContextProvider };