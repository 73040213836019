import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import { ReactComponent as home_icon } from '../../resources/icon/home_icon.svg';
import { ReactComponent as client_icon } from '../../resources/icon/client_icon.svg';
import { ReactComponent as user_icon } from '../../resources/icon/user_icon.svg';
import { ReactComponent as user_plus_icon } from '../../resources/icon/user_plus_icon.svg';
import { ReactComponent as department_icon } from '../../resources/icon/department_icon.svg';
import { ReactComponent as logout_icon } from '../../resources/icon/logout_icon.svg';
import { ReactComponent as incoming_icon } from '../../resources/icon/incoming_icon.svg';
import { ReactComponent as outgoing_icon } from '../../resources/icon/outgoing_icon.svg';
import { ReactComponent as edit_icon } from '../../resources/icon/edit_icon.svg';
import { ReactComponent as delete_icon } from '../../resources/icon/delete_icon.svg';
import { ReactComponent as key_icon } from '../../resources/icon/key_icon.svg';
import { ReactComponent as notification_icon } from '../../resources/icon/notification_icon.svg';
import { LOGO as logo} from '../../resources/icon/logo.js';
import { WHATSAPP_ICON as whatsapp} from '../../resources/icon/whatsapp_icon.js';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import DvrIcon from '@mui/icons-material/Dvr';
import SearchIcon from '@mui/icons-material/Search';
import BoltIcon from '@mui/icons-material/Bolt';
import RttIcon from '@mui/icons-material/Rtt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const Logo = ({ ...props }) => {
  return (
    <Box
      component="img"
      sx={{
        height: 100,
        width: 100,
        maxHeight: 100,
        maxWidth: 100,
      }}
      { ...props }
      alt="The house from the offer."
      src= {logo.LOGO_BASE64_PNG}
    />
  );
};

export const HomeIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={home_icon}
      viewBox='0 0 25 25'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const DepartmentIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={department_icon}
      viewBox='0 0 25 25'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const ClientIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={client_icon}
      viewBox='0 0 25 25'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const UserIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={user_icon}
      viewBox='0 0 25 25'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const UserPlusIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={user_plus_icon}
      viewBox='0 0 25 25'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const NotificacionIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={notification_icon}
      viewBox='0 0 14 14'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const IncomingIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={incoming_icon}
      viewBox='0 0 27 28'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const OutgoingIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={outgoing_icon}
      viewBox='0 0 27 28'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const LogoutIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={logout_icon}
      viewBox='0 0 25 25'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const EditIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={edit_icon}
      viewBox='0 0 25 25'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const DeleteIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={delete_icon}
      viewBox='0 0 25 25'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const KeyIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={key_icon}
      viewBox='0 0 25 25'
      sx={{ fontSize: '1rem', width: 20 }}
      {...props}
    />
  );
};

export const WhatsAppIcon = ({ ...props }) => {
  
  return (
    <Box
      component="img"
      sx={{
        height: 25,
        width: 25,
        maxHeight: 25,
        maxWidth: 25,
      }}
      { ...props }
      alt="The house from the offer."
      src= {whatsapp.WHATSAPP_ICON_BASE64_PNG}
    />
  );
};

export const EndConversationIcon = ({ ...props }) => {
  
  return (
    <CancelIcon 
    sx={{
      height: 25,
      width: 25,
      maxHeight: 25,
      maxWidth: 25,
    }}
    { ...props } />
  );
};

export const TemplateIcon = ({ ...props }) => {
  
  return (
    <DvrIcon 
    sx={{
      height: 25,
      width: 25,
      maxHeight: 25,
      maxWidth: 25,
    }}
    { ...props } />
  );
};

export const SearchIconBit = ({ ...props }) => {
  
  return (
    <SearchIcon 
    sx={{
      height: 25,
      width: 25,
      maxHeight: 25,
      maxWidth: 25,
    }}
    { ...props } />
  );
};

export const BoltIconBit = ({ ...props }) => {
  
  return (
    <BoltIcon 
    sx={{
      height: 25,
      width: 25,
      maxHeight: 25,
      maxWidth: 25,
    }}
    { ...props } />
  );
};

export const RttIconBit = ({ ...props }) => {
  
  return (
    <RttIcon 
    sx={{
      height: 25,
      width: 25,
      maxHeight: 25,
      maxWidth: 25,
    }}
    { ...props } />
  );
};

export const ArrowForwardIconBit = ({ ...props }) => {
  
  return (
    <ArrowForwardIcon 
    sx={{
      height: 25,
      width: 25,
      maxHeight: 25,
      maxWidth: 25,
    }}
    { ...props } />
  );
};
