import React, { createContext, useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { getErrorMessageFromError } from "../../components/ErrorMessage";
import { useApp } from "../../hooks/useApp";
import { CompanyDto } from "../../model/CompanyDto";
import { RoleDto } from "../../model/RoleDto";
import { UserDto } from "../../model/UserDto";
import { ChangePasswordUser, RoleListRequest, CompanyListRequest, UpdateUser, UserDelete, UserListRequest, UserSaveRequest } from "../../services/user/UserService";

const UsersDataContext = createContext();

const UsersDataContextProvider = ({ children }) => {
    const {
        setErrorMsg,
        loading,
        setLoading,
        setSuccessMsg,
        authInfo
    } = useApp();

    const [listUsers, setListUsers] = useState([]);
    const [listRoles, setListRoles] = useState([]);
    const [listCompany, setListCompany] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const [formData, setFormData] = useState({});
    const [clientIdToDelete, setClientIdToDelete] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedRoleId, setSelectedRoleId] = useState('');
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [localSelectedRoleId, setLocalSelectedRoleId] = useState("");
    const [localSelectedCompanyId, setLocalSelectedCompanyId] = useState([])
    const [selectedUser, setSelectedUser] = useState({});
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const [changePasswordError, setChangePasswordError] = useState("");
    const [searchInput, setSearchInput] = useState("");

    const [page, setPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const navigate = useNavigate();

    const handleRoleSelect = (event) => {
        const newSelectedRoleId = event.target.value;
        setSelectedRoleId(newSelectedRoleId);
    }

    const handleCompanySelect = (event) => {
        const newSelectedCompanyId = event.target.value;
        setSelectedCompanyId(newSelectedCompanyId);
    }

    const handleCancel = async () => {
        navigate(-1);
        setFormData({})
        setSelectedRoleId("")
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);        
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    useEffect(() => {
        setTimeout(
            () => loadUsers(), 
            100
        );
    },[page]);

    useEffect(() => {
        if(page == 0)
            setTimeout(
                () => loadUsers(), 
                100
            );
        else
            setPage(0);
    },[rowsPerPage]);

    const loadRoles = async () => {
        if(!authInfo)return;
        try {
            let responseRolesList = await RoleListRequest();
            const rolesDtoList = responseRolesList.map((roleData) => {
                return new RoleDto(
                    roleData.id,
                    roleData.roleName,
                );
            });
            setListRoles(rolesDtoList);
        } catch (error) {
            setLoading(false);
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage)

            setLoading(false);
        }
    };

    const loadCompany = async () => {
        if(!authInfo)return;
        try {
            let responseCompanyList = await CompanyListRequest();
            
            const companyDtoList = responseCompanyList.content.map((companyData) => {
              
                return new CompanyDto(
                    companyData.factor_id,
                    companyData.name,
                   
                );
            });
            setListCompany(companyDtoList);
        } catch (error) {
            setLoading(false);
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage);
        }
    };
    const loadUsers = async () => {
        if(!authInfo)return;
        try {
            setLoading(true);
            let responseUsersList = await UserListRequest(page, rowsPerPage, searchInput);
            setTotalItems(responseUsersList.totalElements);
            const usersDtoList = responseUsersList.content.map((userData) => {
                return new UserDto(
                    userData.id,
                    userData.username,
                    userData.firstName,
                    userData.lastName,
                    userData.identification,
                    userData.status,
                    userData.role.roleName,
                    userData.role.department,
                    userData.activationCode,
                    userData.role.id,
                    '',
                    userData.company,
                );
            });
            setListUsers(usersDtoList);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage);
        }
    };

    const handleFormSubmit = async (values) => {
        values.roleID = localSelectedRoleId;
        values.company = localSelectedCompanyId;
        try {
            const input = {
                ...values,
                role: listRoles.find((role) => role.id === localSelectedRoleId),
                company: localSelectedCompanyId,
            }
            setLoading(true)
            if (values.id) {
                await UpdateUser(input)
                setSuccessMsg("Editado con éxito")
            } else {
                await UserSaveRequest(input)
                setSuccessMsg("Creado con éxito")
            }
            stopEditing();            
            
            if(page == 0)
                setTimeout(
                    () => loadUsers(), 
                    100
                );
            else
                setPage(0);
            setLoading(false)
            handleCancel()
        } catch (error) {
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage)

            setLoading(false)
        }
    };

    const handleOpenChangePasswordModal = (user) => {
        setSelectedUser(user); // Guarda el agente seleccionado en el estado
        setIsChangePasswordModalOpen(true);
    };

    const handleCloseChangePasswordModal = () => {
        setSelectedUser({}); // Reinicia el estado del agente seleccionado cuando se cierra el modal
        setIsChangePasswordModalOpen(false);
    };


    const handleChangePassword = async (user) => {
        try {
            setLoading(true);
            const data = {
                ...user,
                password: user.newPassword,
            };
            await ChangePasswordUser(data);
            handleCloseChangePasswordModal(); // Cierra el modal después de cambiar la contraseña
            setSuccessMsg("Contraseña cambiada con éxito");
            setLoading(false);
            
            if(page == 0)
                setTimeout(
                    () => loadUsers(), 
                    100
                );
            else
                setPage(0); // Actualiza la lista de usuarios después de cambiar la contraseña
            setChangePasswordError(""); // Limpia el mensaje de error en caso de que haya ocurrido un error anteriormente
        } catch (error) {
            const errorMessage = getErrorMessageFromError(error);
            setChangePasswordError("");
            setErrorMsg(errorMessage);
            setLoading(false);
            setChangePasswordError(errorMessage); // Almacena el mensaje de error en el estado
        }
    };

    const openDeleteConfirmation = (clientId) => {
        setClientIdToDelete(clientId);
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setClientIdToDelete(null);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteConfirmed = async () => {
        closeDeleteConfirmation();
        if (clientIdToDelete) {
            await handleDelete(clientIdToDelete);
        }
    };

    const handleDelete = async (clientId) => {
        try {
            setLoading(true);
            await UserDelete(clientId);
            setSuccessMsg("Eliminado con éxito");
            if(page == 0)
                setTimeout(
                    () => loadUsers(), 
                    100
                );
            else
                setPage(0);
            setLoading(false);
        } catch (error) {
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage)
        }
    };

    const startEditing = (initialValues) => {
        setIsEditing(true);
        setFormData(initialValues);
    };

    const stopEditing = () => {
        setIsEditing(false);
        setFormData(null);
    };

    // useEffect(() => {

    //     if (pageSize !== pageSizePrevious.current || page !== pagePrevious.current) {

    //         console.log('pageSizePrevious',pageSize , pageSizePrevious.current);
    //         console.log('pageSizePrevious',page , pagePrevious.current);
    //         loadUsers();
    //         if(pageSize !== pageSizePrevious.current)
    //             pageSizePrevious.current = pageSize;
                
    //         if(page !== pagePrevious.current)
    //             pagePrevious.current = page;
    //     }

    // }, [pageSize,page]);

    return (
        <UsersDataContext.Provider value={{
            listUsers,
            listRoles,
            listCompany,
            handleFormSubmit,
            handleCancel,
            startEditing,
            stopEditing,
            loadUsers,
            openDeleteConfirmation,
            closeDeleteConfirmation,
            handleDeleteConfirmed,
            showDeleteConfirmation,
            isEditing,
            formData,
            handleRoleSelect,
            handleCompanySelect,
            setSelectedRoleId,
            setSelectedCompanyId,
            selectedRoleId,
            selectedCompanyId,
            setLocalSelectedRoleId,
            setLocalSelectedCompanyId,
            localSelectedRoleId,
            localSelectedCompanyId,
            handleChangePassword,
            handleOpenChangePasswordModal,
            handleCloseChangePasswordModal,
            isChangePasswordModalOpen,
            selectedUser,
            changePasswordError,
            loading,
            loadRoles,
            loadCompany,
            searchInput,
            setSearchInput,
            page, 
            setPage,
            rowsPerPage, 
            handleChangePage,
            handleChangeRowsPerPage,
            totalItems
        }}>
            {children}
        </UsersDataContext.Provider>
    );
};

export { UsersDataContext, UsersDataContextProvider };
