import React, { lazy } from "react";
import { createBrowserRouter, createRoutesFromElements, Route, defer } from "react-router-dom";
import BoardLayout from "./components/template/BoardLayaout";
import { AuthLayout } from "./components/security/AuthLayout";
import { getAuthData } from "./utils/LocalStorageManager";
const HomeLayout = lazy(() => import("./components/template/HomeLayout"))
const ForgotPassword = lazy(() => import("./pages/forgotPassword/ForgotPassword"));
const RecoveryPassword = lazy(() => import("./pages/recoveryPassword/RecoveryPassword"));
const ActiveRegister = lazy(() => import("./pages/activeRegister/ActiveRegister"));
const Users = lazy(() => import("./pages/users/Users"));
const MaintenanceUser = lazy(() => import("./pages/users/MaintenanceUsers"));
const Clients = lazy(() => import("./pages/clients/Clients"));
const MaintenanceClient = lazy(() => import("./pages/clients/MaintenanceClient"));
const Companies = lazy(() => import("./pages/companies/Companies"));
const Form = lazy(() => import("./pages/form/Form"));
const Login = lazy(() => import("./pages/login/Login"));
const Whatsapp = lazy(() => import("./pages/whatsapp/Whatsapp"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const TemplateQuickResponse = lazy(() => import("./pages/templatesQuickResponse/TemplatesQuickResponse"));
const MaintenanceTemplateQuickResponse = lazy(() => import("./pages/templatesQuickResponse/MaintenanceTemplatesQuickResponse"));
const TemplatesTwilio = lazy(() => import("./pages/templatesTwilio/TemplatesTwilio"));
const MaintenanceTemplatesTwilio = lazy(() => import("./pages/templatesTwilio/MaintenanceTemplatesTwilio"));
const TemplateAutoReply = lazy(() => import("./pages/autoreply/AutoReply"));
const TemplateAutoReplyStages = lazy(() => import("./pages/autoreply/MaintenanceStages"));
const TemplateAutoReplyResponse = lazy(() => import("./pages/autoreply/MaintenanceResponse"));

export const appRoutes = createBrowserRouter(

  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >

      <Route element={<HomeLayout />}>
        <Route exact path='/' element={<Login />} />
        <Route exact path='/recoverPassword' element={<RecoveryPassword />} />
        <Route
          exact path='/forgotPassword/:code/:email' element={<ForgotPassword />}
        />
        <Route
          exact
          path='/activeRegister/:code/:email'
          element={<ActiveRegister />}
        />
      </Route>

      <Route path="/dashboard" element={<BoardLayout />}>
        <Route exact path="whatsapp" element={<Whatsapp />} />

        <Route path="home" element={<Dashboard />} />
        <Route exact path="users" element={<Users />} />
        <Route exact path="users/maintenanceUser" element={<MaintenanceUser />} />
        <Route exact path="clients" element={<Clients />} />
        <Route exact path="clients/maintenanceClient" element={<MaintenanceClient />} />
        <Route exact path="template/whatsapp/twilio" element={<TemplatesTwilio />} />
        <Route exact path="template/whatsapp/twilio/maintenance" element={<MaintenanceTemplatesTwilio />} />
        <Route exact path="template/whatsapp/quickresponse" element={<TemplateQuickResponse />} />
        <Route exact path="template/whatsapp/quickresponse/maintenance" element={<MaintenanceTemplateQuickResponse />} />
        <Route exact path="template/whatsapp/autoreply" element={<TemplateAutoReply />} />
        <Route exact path="template/whatsapp/autoreply/stages" element={<TemplateAutoReplyStages />} />
        <Route exact path="template/whatsapp/autoreply/stages/responses" element={<TemplateAutoReplyResponse />} />
        <Route exact path="companies" element={<Companies />} />
        <Route exact path="form" element={<Form />} />
      </Route>
    </Route>
  )
)
