import { CompanyDto } from "./CompanyDto";

export class UserDto {
    constructor(id, username, firstName, lastName, identification, status, roleName, department, activationCode, roleID, description, company) {
        this.id = id;
        this.username = username;
        this.firstName = firstName;
        this.lastName = lastName;
        this.identification = identification;
        this.status = status;
        this.role = {
            id: roleID,
            roleName: roleName,
            description: description,
            department: department,
            adminLogin: true,
            clientLogin: true
        };
        this.activationCode = activationCode;
        this.company = company;
    }
}