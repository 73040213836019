import { jwtDecode } from 'jwt-decode';
import { createContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientsDataContextProvider } from '../pages/clients/ClientDataContext';
import { CompaniesDataContextProvider } from '../pages/companies/CompaniesDataContext';
import { SidebarDataContextProvider } from '../pages/global/SidebarDataContext';
import { UsersDataContextProvider } from '../pages/users/UsersContext';
import { setAuthData } from '../utils/LocalStorageManager';
import { TemplatesQuickResponseDataContextProvider } from '../pages/templatesQuickResponse/TemplatesQuickResponseDataContext';
import { TemplatesTwilioDataContextProvider } from '../pages/templatesTwilio/TemplatesTwilioDataContext';
import { TemplatesAutoReplyDataContextProvider } from '../pages/autoreply/TemplatesAutoReplyDataContext';


const initialState = {
  isLoading: false,
  errorMsg: '',
  successMsg: '',
};

const AppContext = createContext(initialState);
const AppProvider = ({ children, authData }) => {
  const [authInfo, setAuthInfo] = useState(authData);
  const [loading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: '',
    modalObject: null,
  });
  const [messageUser, setMessageUser] = useState([])
  const [authenticate, setAuthenticate] = useState(authData != null);
  const navigate = useNavigate();

  const [newMessageTimeout, setNewMessageTimeout] = useState([])

  const loginProvider = async (loginData) => {
    try {
      const sessionCode = loginData.tokenInfo;
      const email = loginData.username;

      const authData = await setAuthData("authAdminInfo", loginData);
      if (!authData) {
        setAuthenticate(false);
        return;
      }
      // console.log(authData);
      const decodeToken = jwtDecode(authData?.tokenInfo?.access_token);
      const expiresAt = decodeToken?.exp;
      let valid = new Date().getTime() / 1000 < expiresAt;
      if (valid) {
        // console.log('valid');
        setAuthenticate(valid);
        setAuthInfo(authData);
        // console.log(authData);
        navigate("/dashboard/home", { replace: true });
        return;
      }
      if (sessionCode && email) {
        // console.log('desde session');
        // const loginDataValidate = await SVSessionValidateRequest(
        //   email,
        //   sessionCode
        // );
        //save login data in storage
        const authData = await setAuthData(
          "authAdminInfo",
          sessionCode
        );
        // console.log(authData);
        if (!authData) {
          setAuthenticate(false);
          return;
        }
        const decodeToken = jwtDecode(authData?.tokenInfo?.access_token);
        const expiresAt = decodeToken?.exp;
        let valid = new Date().getTime() / 1000 < expiresAt;
        // console.log(authData);
        // console.log('No valido');
        // console.log(valid);

        //let valid = true;
        if (valid) {
          // console.log('Es valido');
          setAuthenticate(valid);
          setAuthInfo(authData);
          navigate("/dashboard/home", { replace: true });
          return;
        }
      }
    } catch (error) {
      // console.log(error);
      setAuthenticate(false);
    }
    setAuthenticate(false);
  };

  const logoutProvider = async (e) => {
    //e.preventDefault();
    await setAuthData("authAdminInfo", null);
    setAuthenticate(false);
    // console.log(authenticate);
    navigate("/", { replace: true });
  };

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg('');
  };

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg('');
  };

  const formContextValues = useMemo(
    () => ({
      // authInfo,
      authenticate,
      loading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      messageUser,
      setMessageUser,
      setSuccessMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      authData,
      authInfo,
      resetErrorMsg,
      resetSuccessMsg,
      newMessageTimeout,
      setNewMessageTimeout
    }),
    // eslint-disable-next-line
    [authInfo,
      authenticate,
      loading,
      errorMsg,
      successMsg,
      modalData,
      messageUser,
      newMessageTimeout,
      setNewMessageTimeout
    ]
  );

  return (
    <AppContext.Provider value={formContextValues}>
      <SidebarDataContextProvider>
        <CompaniesDataContextProvider>
          <UsersDataContextProvider>
            <ClientsDataContextProvider>
              <TemplatesQuickResponseDataContextProvider>
                <TemplatesTwilioDataContextProvider>
                  <TemplatesAutoReplyDataContextProvider>
                    {children}
                  </TemplatesAutoReplyDataContextProvider>
                </TemplatesTwilioDataContextProvider>
              </TemplatesQuickResponseDataContextProvider>
            </ClientsDataContextProvider>
          </UsersDataContextProvider>
        </CompaniesDataContextProvider>
      </SidebarDataContextProvider>
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;
