import { CONSTANT } from "../../utils/Constant";
import { privateFetch } from "../../utils/CustomFetch";

export const createFlowChatRequest = async (data) => {
    try {
        const response = await privateFetch
            .post('reply/v1/backoffice/flow', data, {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            });

        return response.data;

    } catch (error) {

        throw error;
    }
};

export const updateFlowChatRequest = async (data) => {
    let id = data.id;
    try {
        const response = await privateFetch
            .patch(`reply/v1/backoffice/flow/${id}`, data, {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            });

        return response.data;

    } catch (error) {

        throw error;
    }
};

export const deleteFlowChatRequest = async (id) => {
    let responseData = [];
    await privateFetch
        .delete(`reply/v1/backoffice/flow/${id}`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            }
        })
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error(error);
        });
    return responseData;
};


export const getFlowChatRequest = async () => {
    let responseData = [];
    await privateFetch
        .get(`reply/v1/backoffice/flow`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            }
        })
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error(error);
        });
    return responseData;
};


export const getStagesRequest = async (flowId) => {
    let responseData = [];
    await privateFetch
        .get(`reply/v1/backoffice/flow/${flowId}/node`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            }
        })
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error(error);
        });
    return responseData;
};


export const createStageRequest = async (data,flowId) => {
    try {
        const response = await privateFetch
            .post(`reply/v1/backoffice/flow/${flowId}`, data, {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            });

        return response.data;

    } catch (error) {

        throw error;
    }
};

export const updateStageRequest = async (data,flowId,id) => {
    try {
        const response = await privateFetch
            .patch(`reply/v1/backoffice/flow/${flowId}/node/${id}`, data, {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            });

        return response.data;

    } catch (error) {

        throw error;
    }
};

export const deleteStageRequest = async (flowId, nodeId) => {
    let responseData = [];
    await privateFetch
        .delete(`reply/v1/backoffice/flow/${flowId}/node/${nodeId}`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            }
        })
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error(error);
        });
    return responseData;
};


export const getResponsesRequest = async (flowId, nodeId) => {
    let responseData = [];
    await privateFetch
        .get(`reply/v1/backoffice/flow/${flowId}/node/${nodeId}`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            }
        })
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error(error);
        });
    return responseData;
};




export const createResponseRequest = async (data,flowId, nodeId) => {
    try {
        const response = await privateFetch
            .post(`reply/v1/backoffice/flow/${flowId}/node/${nodeId}`, data, {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            });

        return response.data;

    } catch (error) {

        throw error;
    }
};

export const updateResponseRequest = async (data, flowId, nodeId, id) => {
    try {
        const response = await privateFetch
            .patch(`reply/v1/backoffice/flow/${flowId}/node/${nodeId}/response/${id}`, data, {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            });

        return response.data;

    } catch (error) {

        throw error;
    }
};

export const deleteResponseRequest = async (flowId, nodeId, id) => {
    let responseData = [];
    await privateFetch
        .delete(`reply/v1/backoffice/flow/${flowId}/node/${nodeId}/response/${id}`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            }
        })
        .then(async (response) => {
            responseData = await response.data;
        })
        .catch((error) => {
            throw new Error(error);
        });
    return responseData;
};