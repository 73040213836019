import { CONSTANT } from "../../utils/Constant";
import { privateFetch } from "../../utils/CustomFetch";

export const WhatsAppSendMessageRequest = async (data) => {
  let responseData = {}
  const requestBody = {
    body: data.message,
    client: data.client,
    date: new Date(),
    fromNumber: "",
    id: 0,
    messageIncoming: false,
    messageSid: "",
    numMedia: "",
    numSegments: "",
    profileName: "",
    referralNumMedia: "",
    smsStatus: "",
    toNumber: data.client.cellPhone,
    whatsappMedia: data.whatsappMedia ? [
      {
        id: 0,
        mediaContentType: data.whatsappMedia.mediaContentType,
        mediaName: data.whatsappMedia.mediaName,
        mediaUrl: data.whatsappMedia.mediaUrl,
      }
    ] : [],
  }

  // console.log(requestBody);

  await privateFetch
    .post('notification/whatsapp/send', requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch((error) => {
      throw new Error(error)
    });

  return responseData
}

// await privateFetch.post('file/upload/send',


export const WhatsAppSendFile = async (idClient, fileName, file) => {
  try {
    // console.log(idClient, fileName, file);
    const date = new Date();
    const formattedDate = date.toISOString().slice(11, 19).replace(/:/g, '');
    const fileNameWithTime = `${formattedDate}_${fileName}`;

    const sendFile = {
      'file': file,
      'path': idClient.id,
      'fileName': fileNameWithTime
    }

    // console.log(sendFile);

    const response = await privateFetch.post('file/upload', sendFile, {
      headers: {
        ...CONSTANT.HEADER_TYPE_FILE,
      },
    });
    // console.log(response);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw new Error('Error uploading file');
  }
}

export const WhatsAppGetMessageListRequest = async (clientId, page, limit, idMessageOld) => {
  let responseData = [];
  await privateFetch
    .get(`notification/whatsapp/messages/${clientId}/${idMessageOld}/${page}/${limit}`, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      await response.data.content.forEach((el) => {
        el.dateFormat = new Date(el.date).toLocaleString();// Formatear la fecha
      });
      responseData = response.data;
    })
    .catch((error) => {
      console.log(error);
      throw new Error(error);
    });
  return responseData;
};

export const notificationNewCountMessage = async (userId, rolId) => {
      let responseData = [];
  await privateFetch
    .get(`notification/whatsapp/countMessages/new/${userId}/${rolId}`, {

      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });

  return responseData;
};

export const notificationNewMessage = async (idClient,idMessageNew) => {
  let responseData = [];
  await privateFetch
    .get(`notification/whatsapp/messages/new/${idClient}/${idMessageNew}`, {

      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      await response.data.content.forEach((el) => {
        el.dateFormat = new Date(el.date).toLocaleString();// Formatear la fecha
      });
      responseData = response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });

  return responseData;
};

export const endConversationRequest = async (clientId) => {
  let responseData = [];
  await privateFetch
    .put(`notification/whatsapp/endConversation/${clientId}`, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = response.data;
    })
    .catch((error) => {
      console.log(error);
      throw new Error(error);
    });
  return responseData;
};

export const transferConversationRequest = async (clientId, companyId) => {
  let responseData = [];
  await privateFetch
    .put(`notification/whatsapp/transferConversation/${clientId}/${companyId}`, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = response.data;
    })
    .catch((error) => {
      console.log(error);
      throw new Error(error);
    });
  return responseData;
};

export const downloadFileBase64 = async (messageSid) => {
  let responseData = null;

  await privateFetch
    .get(`notification/whatsapp/downloadFileBase64/${messageSid}`, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })  
    .then(async (response) => {
      responseData = response;
    })
    .catch((error) => {
      throw new Error(error);
    });

  return responseData;
};