import { createContext, useState } from "react";
import { getErrorMessageFromError } from "../../components/ErrorMessage";
import { useApp } from "../../hooks/useApp";
import { useNavigate } from "react-router-dom";
import {
    getFlowChatRequest, deleteFlowChatRequest, updateFlowChatRequest, createFlowChatRequest, getStagesRequest,
    getResponsesRequest, createStageRequest, updateStageRequest, deleteStageRequest, createResponseRequest, updateResponseRequest,
    deleteResponseRequest
} from "../../services/autoreply/AutoReplyService";

const TemplatesAutoReplyDataContext = createContext();

const TemplatesAutoReplyDataContextProvider = ({ children }) => {
    const [listFlowsAutoReply, setListFlowsAutoReply] = useState([]);
    const [templateIdToDelete, setTemplateIdToDelete] = useState(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEditFlowModalOpen, setIsEditFlowModalOpen] = useState(false);
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [listStages, setListStages] = useState([]);
    const [isEditStageModalOpen, setIsEditStageModalOpen] = useState(false);
    const [selectedStage, setSelectedStage] = useState(null);
    const [stageIdToDelete, setStageIdToDelete] = useState(0);
    const [showDeleteStageConfirmation, setShowDeleteStageConfirmation] = useState(false);
    const [listResponses, setListResponses] = useState([]);
    const [isEditResponseModalOpen, setIsEditResponseModalOpen] = useState(false);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const [responseIdToDelete, setResponseIdToDelete] = useState(0);
    const [showDeleteResponseConfirmation, setShowDeleteResponseConfirmation] = useState(false);


    const { setSuccessMsg,
        setErrorMsg,
        setLoading,
        messageUser,
        loading,
        authInfo
    } = useApp();

    const navigate = useNavigate();

    const handleCancel = async () => {
        navigate(-1);
    };



    const openDeleteConfirmation = (templateId) => {
        setTemplateIdToDelete(templateId);
        setShowDeleteConfirmation(true);
    };


    const closeDeleteConfirmation = () => {
        setTemplateIdToDelete(null);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteConfirmed = async () => {
        closeDeleteConfirmation();
        if (templateIdToDelete) {
            await handleDelete(templateIdToDelete);
        }
    };



    const loadFlowsAutoReply = async () => {
        if(!authInfo)return;
        try {
            let responseTemplatesAutoReplyList = await getFlowChatRequest();
            setListFlowsAutoReply(responseTemplatesAutoReplyList.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }

    const handleDelete = async (templateId) => {
        try {
            setLoading(true)
            await deleteFlowChatRequest(templateId);
            setSuccessMsg("Eliminado con éxito")
            loadFlowsAutoReply();
            setLoading(false)
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    };

    //Edicion y creacion de flujos
    const handleEditFlowOpenModal = () => {
        setIsEditFlowModalOpen(true);
    };

    const handleEditFlowCloseModal = () => {
        setIsEditFlowModalOpen(false);
        setSelectedFlow(null)
    };


    const handleEditFlowFormSubmit = async (values) => {
        try {
            const input = {
                ...values
            };
            setLoading(true)
            if (values.id) {
                await updateFlowChatRequest(input);
                setSuccessMsg("Editado con éxito");

            } else {
                await createFlowChatRequest(input);
                setSuccessMsg("Creado con éxito")
            }
            loadFlowsAutoReply();
            setLoading(false);
            handleEditFlowCloseModal();
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }
    const onClickActionEditFlowButton = (params) => {
        console.log('onClickActionEditButton', params)
        setSelectedFlow(params.row);
        handleEditFlowOpenModal()
    };



    //Edicion y creacion de stages

    const loadStages = async (flowId) => {
        if(!authInfo)return;
        try {
            let stagesList = await getStagesRequest(flowId);
            setListStages(stagesList.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }


    const handleEditStageOpenModal = () => {
        setIsEditStageModalOpen(true);
    };

    const handleEditStageCloseModal = () => {
        setIsEditStageModalOpen(false);
        setSelectedStage(null)
    };

    const handleStageFormSubmit = async (values, flowId, id) => {
        console.log(values);
        try {
            const input = {
                ...values
            };
            setLoading(true)
            if (values.id) {
                await updateStageRequest(input, flowId, id);
                setSuccessMsg("Editado con éxito");

            } else {
                await createStageRequest(input, flowId);
                setSuccessMsg("Creado con éxito")
            }
            loadStages(flowId);
            setLoading(false);
            handleEditStageCloseModal();
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }

    const onClickActionEditStageButton = (params) => {
        console.log('onClickActionEditButton', params)
        setSelectedStage(params.row);
        handleEditStageOpenModal()
    };


    const openDeleteStageConfirmation = (stageId) => {
        setStageIdToDelete(stageId);
        setShowDeleteStageConfirmation(true);
    };

    const handleStageDelete = async (flowId, nodeId) => {
        try {
            setLoading(true)
            await deleteStageRequest(flowId, nodeId);
            setSuccessMsg("Eliminado con éxito")
            loadStages(flowId);
            setLoading(false)
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    };

    const closeDeleteStageConfirmation = () => {
        setStageIdToDelete(null);
        setShowDeleteStageConfirmation(false);
    };

    const handleDeleteStageConfirmed = async (flowId) => {
        closeDeleteStageConfirmation();
        if (stageIdToDelete) {
            await handleStageDelete(flowId, stageIdToDelete);
        }
    };

    //edicion y creacion de respuestas esperadas
    const loadResponses = async (flowId, stageId) => {
        if(!authInfo)return;
        try {
            let responsesList = await getResponsesRequest(flowId, stageId);
            setListResponses(responsesList.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }

    const handleResponseFormSubmit = async (values, flowId, stageId) => {
        console.log('values:', values)
        try {
            const input = {
                ...values,
                nodeId: stageId
            };
            setLoading(true)
            if (values.id) {
                await updateResponseRequest(input, flowId, stageId, values.id);
                setSuccessMsg("Editado con éxito");

            } else {
                await createResponseRequest(input, flowId, stageId);
                setSuccessMsg("Creado con éxito")
            }
            loadResponses(flowId, stageId);
            setLoading(false);
            handleEditResponseCloseModal();
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }

    const handleEditResponseOpenModal = () => {
        setIsEditResponseModalOpen(true);
    };

    const handleEditResponseCloseModal = () => {
        setIsEditResponseModalOpen(false);
        setSelectedResponse(null)
    };

    const onClickActionEditResponseButton = (params) => {
        console.log('onClickActionEditButton', params)
        setSelectedResponse(params.row);
        handleEditResponseOpenModal()
    };


    const openDeleteResponseConfirmation = (responseId) => {
        setResponseIdToDelete(responseId);
        setShowDeleteResponseConfirmation(true);
    };

    const closeDeleteResponseConfirmation = () => {
        setResponseIdToDelete(null);
        setShowDeleteResponseConfirmation(false);
    };


    const handleResponseDelete = async (flowId, nodeId, id) => {
        try {
            setLoading(true)
            await deleteResponseRequest(flowId, nodeId, id);
            setSuccessMsg("Eliminado con éxito")
            loadResponses(flowId, nodeId);
            setLoading(false)
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    };

    const handleDeleteResponseConfirmed = async (flowId, nodeId) => {
        closeDeleteResponseConfirmation();
        if (responseIdToDelete) {
            await handleResponseDelete(flowId, nodeId,responseIdToDelete);
        }
    };


    return (
        <TemplatesAutoReplyDataContext.Provider value={{
            listFlowsAutoReply,
            loadFlowsAutoReply,
            loading,
            messageUser,
            showDeleteConfirmation,
            openDeleteConfirmation,
            closeDeleteConfirmation,
            handleDeleteConfirmed,
            handleCancel,
            handleEditFlowOpenModal,
            handleEditFlowCloseModal,
            isEditFlowModalOpen,
            handleEditFlowFormSubmit,
            selectedFlow,
            onClickActionEditFlowButton,
            listStages,
            loadStages,
            handleStageFormSubmit,
            isEditStageModalOpen,
            handleEditStageCloseModal,
            handleEditStageOpenModal,
            onClickActionEditStageButton,
            openDeleteStageConfirmation,
            closeDeleteStageConfirmation,
            handleDeleteStageConfirmed,
            showDeleteStageConfirmation,
            selectedStage,
            listResponses,
            loadResponses,
            handleResponseFormSubmit,
            isEditResponseModalOpen,
            handleEditResponseCloseModal,
            handleEditResponseOpenModal,
            onClickActionEditResponseButton,
            openDeleteResponseConfirmation,
            closeDeleteResponseConfirmation,
            handleDeleteResponseConfirmed,
            showDeleteResponseConfirmation,
            selectedResponse
        }}>
            {children}
        </TemplatesAutoReplyDataContext.Provider>
    )
}

export { TemplatesAutoReplyDataContext, TemplatesAutoReplyDataContextProvider };