export class ClientDto {
    constructor(id, name, first_surname, second_surname, cellPhone, email, prospect, identification, company, company_name, status){
        this.id = id;
        this.name = name;
        this.firstSurname = first_surname;
        this.secondSurname = second_surname;
        this.cellPhone = cellPhone;
        this.email = email;
        this.prospect = prospect;
        this.identification = identification;
        this.company = company;
        this.company_name = company_name ?? 'Sin asignar';
        this.status = status ?? 'ACTIVE';
    }
}