import { privateFetch } from "../../utils/CustomFetch"
import { CONSTANT } from "../../utils/Constant"


export const UserDelete = async (id) => {
    try {
        const response = await privateFetch.delete(`users/user/${id}`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        });

        if (response.status === 200) {
            if (response.data.info === 'DELETED') {
                return response.data.info;
            } else {
                throw new Error("La respuesta no contiene la información esperada.");
            }
        } else {
            throw new Error("Error al eliminar.");
        }
    } catch (error) {
        throw error;
    }
};

export const UserListRequest = async (page, size, searchInput) => {
    let responseData = [];

    await privateFetch
        .get(`users/user/${page}/${size}?filter=${searchInput}`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            }
        })
        .then(async (response) => {
            responseData = await response.data
        })
        .catch((error) => {
            throw new Error(error)
        });

    return responseData
}

export const RoleListRequest = async () => {
    let responseData = []
    await privateFetch
        .get(`users/role/`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        })
        .then(async (response) => {
            responseData = await response.data
        })
        .catch((error) => {
            throw new Error(error)
        });

    return responseData
}


export const CompanyListRequest = async () => {

    let responseData = []
    await privateFetch
        .get(`maintenance/company/filter/?page=0&size=1000`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        })
        .then(async (response) => {
            responseData = await response.data
        })
        .catch((error) => {
            throw new Error(error)
        });

    return responseData
}

export const UpdateUser = async (data) => {

    try {
        // console.log(data);
        const response = await privateFetch.put('users/user/', data, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    };
}

export const UserSaveRequest = async (data) => {
    // console.log(data);
    try {
        const response = await privateFetch.post('users/user/create', data, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        });

        return response.data;

    } catch (error) {

        throw error;
    }
};

export const ChangePasswordUser = async (data) => {
    try {
        const response = await privateFetch.put('users/user/changePassword', data, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        });

        return response.data;
    } catch (error) {

        throw error;
    };
}