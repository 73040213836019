import { privateFetch } from "../../utils/CustomFetch"
import { CONSTANT } from "../../utils/Constant"

export const ClientDelete = async (id) => {
    try {
        const response = await privateFetch.delete(`maintenance/client/${id}`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        });
        if (response.data && response.data.error === false && response.data.responseCode === 200) {
            return response.data.message;
        } else {
            throw new Error("Error al eliminar.");
        }
    } catch (error) {
        throw error;
    }
};

export const ClientSaveRequest = async (data) => {
    try {
        const response = await privateFetch.post('maintenance/client', data, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const ClientUpdatedRequest = async (data) => {
    try {
        const response = await privateFetch.put('maintenance/client', data, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const ClientListRequest = async ( page, limit, companyId, userId, rolId, query) => {
    let responseData = []
    await privateFetch
        .get(`maintenance/client/list/${userId}/${rolId}/${page}/${limit}?company=${companyId ?? ''}&query=${query ?? ''}`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            }
        })
        .then(async (response) => {
            responseData = await response.data
        })
        .catch((error) => {
            throw new Error(error)
        });

    return responseData
}

export const UpdateLead = async (data) => {
    let responseData = {}

    await privateFetch
        .put('maintenance/client', data, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        })
        .then(async (response) => {
            responseData = await response.data
        })
        .catch((error) => {
            throw new Error(error)
        });

    return responseData
}